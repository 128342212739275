import { MarketListCustomEvent } from '@market/web-components';
import { TMarketListSelectionsDidChangeEventDetail } from '@market/web-components/dist/types/components/market-list/events';
import {
  ChangelogEntryPage,
  ChangelogSetPage,
} from '@squareup/dex-types-shared-docs';
import { Head } from '@squareup/dex-ui';
import { DocsPageContentLayout } from '@squareup/dex-ui-dex-tech-docs-page-layout';
import { Box, Heading20, Paragraph30 } from '@squareup/dex-ui-shared-base';
import {
  MarketCheckbox,
  MarketList,
  MarketRow,
  MarketButtonDropdown,
  MarketFilterButton,
} from '@squareup/dex-ui-shared-market';
import { getNextPublicDocsNameOrDefault } from '@squareup/dex-utils-environment';
import React, { FC, useEffect, useState } from 'react';

import { DocPageHeading } from '../DocPageHeading';
import { homeBreadcrumb } from '../markdown-block-hooks';

import { ChangelogEntryLink } from './ChangelogEntryLink';
import styles from './ChangelogSetPage.module.css';

interface ChangeLogSetPageProps {
  page: ChangelogSetPage;
}

const ChangelogSetPageContent: FC<ChangeLogSetPageProps> = ({ page }) => {
  const [filterList, setFilterList] = useState<string[]>([]);
  const [selected, setSelected] = useState<Set<string>>(new Set());

  useEffect(() => {
    const filterSet = new Set<string>();
    [...page.changelogHistory].forEach((entry) => {
      entry.tags.forEach((tag) => filterSet.add(tag));
    });
    const orderedFilters = [...filterSet].sort((a, b) => a.localeCompare(b));
    setFilterList(orderedFilters);
  }, [page.changelogHistory]);

  const breadcrumbs = [
    homeBreadcrumb,
    {
      title: 'Release Notes',
      href: 'release-notes',
    },
  ];

  const selectedList = [...selected];
  const filtered = [...page.changelogHistory].filter((entry) => {
    if (selected.size === 0) {
      return true;
    }
    const tags = new Set(entry.tags);
    for (const filter of selectedList) {
      // If the entry has any of the filters, we show it
      if (tags.has(filter)) {
        return true;
      }
    }
    return false;
  });

  const cmpFn = (a: ChangelogEntryPage, b: ChangelogEntryPage) => {
    const dateA = new Date(a.changelogDate);
    const dateB = new Date(b.changelogDate);
    return dateB.getTime() - dateA.getTime();
  };

  const sortedHistory = filtered.sort(cmpFn);
  const latestEntry = [...page.changelogHistory].sort(cmpFn)[0];

  const onFilterChange = (
    event: MarketListCustomEvent<TMarketListSelectionsDidChangeEventDetail>
  ) => {
    const { currentSelectionValues } = event.detail;
    setSelected(new Set(currentSelectionValues));
  };
  const headingTitle =
    page?.heading?.title && `Release Notes: ${page.heading.title}`;

  return (
    <DocsPageContentLayout>
      <>
        <Head
          title={
            page?.heading?.browserTitle ||
            headingTitle ||
            getNextPublicDocsNameOrDefault()
          }
          description={page?.heading?.summary || ''}
          canonicalPath={`/docs/${page.slug}`}
        />
        <DocPageHeading
          heading={{ title: page.heading.title }}
          breadcrumbItems={breadcrumbs}
        />
        <Box className={styles['filter-container']}>
          <MarketButtonDropdown
            interaction="persistent"
            popoverPlacement="bottom-start"
            persistListSelections
          >
            <MarketFilterButton slot="trigger">
              Updates
              <Paragraph30
                weight="semi-bold"
                margin={{ left: '1x' }}
                slot="feedback"
              >
                {selectedList.length === 0 ||
                selectedList.length === filterList.length
                  ? 'All'
                  : selectedList.length > 1
                  ? `Filter (${selectedList.length})`
                  : selectedList.join(', ')}
              </Paragraph30>
            </MarketFilterButton>
            <MarketList
              multiselect
              value={selectedList.join(',')}
              onMarketListSelectionsDidChange={onFilterChange}
              slot="content"
            >
              <MarketRow
                size="small"
                value="all"
                slot="control-row"
                trackingId="all-checkbox-row"
              >
                <label slot="label">
                  <Paragraph30 weight="medium">Select all</Paragraph30>
                </label>
                <MarketCheckbox slot="control" />
              </MarketRow>
              {filterList.map((filter) => {
                return (
                  <MarketRow
                    key={filter}
                    size="small"
                    value={filter}
                    trackingId="filter-checkbox-row"
                  >
                    <label slot="label">
                      <Paragraph30 weight="medium">{filter}</Paragraph30>
                    </label>
                    <MarketCheckbox slot="control" />
                  </MarketRow>
                );
              })}
            </MarketList>
          </MarketButtonDropdown>
        </Box>
        <Box className={styles.content}>
          {sortedHistory.map((entry) => (
            <ChangelogEntryLink
              key={entry.changelogDate}
              entry={entry}
              isLatest={entry === latestEntry}
            />
          ))}
          {sortedHistory.length === 0 && (
            <>
              <Heading20 margin={{ top: '3x' }}>No results found</Heading20>
              <Paragraph30 margin={{ top: '0.75x' }}>
                Adjust your filters and try again.
              </Paragraph30>
            </>
          )}
        </Box>
      </>
    </DocsPageContentLayout>
  );
};

export { ChangelogSetPageContent };
