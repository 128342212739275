import {
  ChangelogEntryPageContent,
  DocsPageContent,
  isDocsAdminToolsEnabled,
  AdminToolbar,
  ChangelogSetPageContent,
} from '@squareup/dex-feature-docs-page';
import { ReactNode, useMemo } from 'react';

import { DocsBasePage, DocsBasePageProps } from '../../shared/DocsBasePage';
import { getInitialPropsDocPage } from '../../shared/docs-get-initial-props';

const Page = (data: DocsBasePageProps) => {
  const page = data.pageInView?.page;
  const adminToolbar: ReactNode = useMemo(() => {
    return (
      <AdminToolbar
        features={{
          showMarkdownEnabled: true,
          viewDiffEnabled: !data.releaseTrain && !data.preview,
          viewContentfulEntryEnabled: true,
          showPreviewEnabled: !data.releaseTrain,
          releaseTrainEnabled: !data.preview,
          showHtmlHighlightEnabled: true,
          contentfulEnvironmentEnabled: !data.releaseTrain,
          unpublishedOasEnabled: true,
        }}
        contentfulEntryId={page?.id}
        isPreview={data.preview}
      />
    );
  }, [data?.preview, data?.releaseTrain, page?.id]);

  if (!page) {
    return null;
  }

  let pageContent: ReactNode;

  if (page.type === 'changelog-entry-page') {
    pageContent = (
      <ChangelogEntryPageContent
        page={page}
        navSet={data?.navSet?.value || undefined}
      ></ChangelogEntryPageContent>
    );
  } else if (page.type === 'changelog-set-page') {
    pageContent = (
      <ChangelogSetPageContent page={page}></ChangelogSetPageContent>
    );
  } else {
    pageContent = (
      <DocsPageContent
        // This forces the useState calls to reset when we get pages,
        // especially the ones in the `useRequirements` hook since it uses
        // default state.
        // Otherwise, the internal `useRequirements` call will no-op, and you will
        // not fetch new requirements
        key={page.id}
        docPage={page}
        navSet={data?.navSet?.value || undefined}
        requirementDomain={data?.pageInView?.requirementsDomain || undefined}
        contentfulEnvironment={data.contentfulEnvironment}
        releaseTrain={data.releaseTrain}
      ></DocsPageContent>
    );
  }

  return (
    <>
      {pageContent}
      {isDocsAdminToolsEnabled() && adminToolbar}
    </>
  );
};

const WrapperPage = (data: DocsBasePageProps) => {
  return (
    <DocsBasePage {...data}>
      <Page {...data} />
    </DocsBasePage>
  );
};

WrapperPage.getInitialProps = getInitialPropsDocPage;

export default WrapperPage;
