import { ChangelogEntryPage } from '@squareup/dex-types-shared-docs';
import { Link } from '@squareup/dex-ui';
import { Box, Paragraph10 } from '@squareup/dex-ui-shared-base';
import { MarketPill } from '@squareup/dex-ui-shared-market';
import React, { FC } from 'react';

import styles from './ChangelogSetPage.module.css';

interface ChangelogEntryLinkProps {
  entry: ChangelogEntryPage;
  isLatest: boolean;
}

const ChangelogEntryLink: FC<ChangelogEntryLinkProps> = ({
  entry,
  isLatest,
}) => {
  const cleanDate = entry.changelogDate.replace(/T.*/, '');
  return (
    <Box
      border={{ line: { bottom: 'standard' } }}
      padding={{ vertical: '2x' }}
      className={styles['wrap']}
    >
      <Box className={styles['top']}>
        <Link
          trackingId="changelog-entry-link"
          testId="changelog-entry-link"
          href={entry.slug}
          className={styles['link'] ?? ''}
        >
          {cleanDate}
        </Link>
        {isLatest && (
          <Box className={styles.pill}>
            <MarketPill variant="emphasis">Latest</MarketPill>
          </Box>
        )}
      </Box>
      <Box className={styles['tag-wrap']} padding={{ top: '1x' }}>
        {entry.tags.map((tag) => (
          <Paragraph10 key={tag} className={styles.tag}>
            {tag}
          </Paragraph10>
        ))}
      </Box>
    </Box>
  );
};

export { ChangelogEntryLink };
